import request from '../utils/axios';
import { appCode, getIMEI } from '../utils/tools'

// 线上
const originApi = 'https://sem.rlcmarketltd.net/api/proxy';

// index--昨收报价
export function quote(data) {
  return request({
    url:'https://sem.rlclead.com/api/quote/v1/QF1002',
    method: 'POST',
    data
  })
}
// index--行情
export function quoteTime(data) {
  return request({
    url:'https://sem.rlclead.com/api/quote/v1/QF1001',
    method: 'POST',
    data
  })
}
//banner图配置
export function swiperBanner(data){
  return request({
    url: originApi + '/v4/PA159',
    method: 'POST',
    data
  })
}
// export function swiperBanner(data){
//   return request({
//     url: originApi + '/common/brandLineAndImageList',
//     method: 'POST',
//     data
//   })
// }
//图片验证码
export function verifyImgCode(data){
  return request({
    url: originApi + '/v4/U001',
    method: 'POST',
    data
  })
}
//快捷注册
export function quickRegister(data){
  data.ClientId = getIMEI();
  data.IsMt5 = true;
  return request({
    url: originApi + '/v4/U101',
    method: 'POST',
    data,
    headers: {
      platform: 1
    }
  })
}
//pm登录
export function pmLogin(data){
  return request({
    url: originApi + '/v4/U103',
    method: 'POST',
    data
  })
}
// 获取live800
export function getLive800(params) {
  return request({
    url: originApi + '/v4/ACV100',
    method: 'get',
    params
  })
}

// crm埋点
export function maidianOpenreal (data) {
  return request({
    // url:'http://kafka.crm.lan/scada/collect',  // 测试
    url:'https://crm.njanea.com:12343/maidian/scada/collect',  // 生产环境
    method: 'POST',
    data: [
      {
        sourceid: 1,
        usertype: data.usertype ? data.usertype : 2,
        clientid: getIMEI(),
        eventtime: new Date().getTime(),
        detail: {
          ...data.detail,
          channelname: '手动输入',
          backurl: window.location.href,
          currenturl: window.location.href,
          account: data.account,
          accounttype: data.accounttype,
          packageid: data.packageid || appCode()['v'] || appCode()['channel'],
          medium: appCode()['utm_medium'] || undefined, //媒介
          keyworld: appCode()['utm_term'] || undefined, //关键字
          plan: appCode()['utm_campaign'] || undefined, //计划
          unit: appCode()['utm_content'] || undefined, //单元
        },
        eventname: data.eventname,
        eventdescribe: data.eventdescribe,
        eventtype: data.eventtype || 'visit',
      }
    ]
  })
}
